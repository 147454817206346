import moment from 'moment';

const initialYear = moment([2020]);
const today = moment();
const length = today.diff(initialYear, "years") + 1;
const options = Array(length)
  .fill(0)
  .map((_, index) => {
    const date = moment().subtract(index, "years");
    return {
      text: date.format("YYYY"),
      value: date.get('year'),
    };
  });

export default options;

export const projectsOptions = Array(5)
  .fill(0)
  .map((_, index) => {
    const date = moment().add(index - 2, "years");
    return {
      text: date.format("YYYY"),
      value: date.get('year'),
    };
  })
  .reverse();
